import { Property } from 'csstype';
import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import { Cell } from 'react-table';

import { TimeRange, DataFrame, SelectableValue, ThresholdsConfig } from '@grafana/data';

import { IconName } from '../../types/icon';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Switch } from '../Switch/Switch';
import { Tooltip } from '../Tooltip/Tooltip';

import { TableStyles } from './styles';
import { GrafanaTableColumn, TableFilterActionCallback } from './types';

interface HighlightOption {
  basedOn: { name: string; column: number };
  matcher: {
    operator: string;
    value: string;
  };
  color: string;
  backgroundColor: string;
  icon?: IconName;
  iconPosition?: 'start' | 'end';
  iconColor?: string;
  iconSize?: 'small' | 'medium' | 'large';
  isActive: boolean;
  tooltip?: string;
  iconAnimation?: string;
}

export interface ClickableCell {
  isClickable: boolean;
  topic?: string | undefined;
  rowNo?: number;
  type?: 'string' | 'number' | 'button' | 'select' | 'switch';
  queryRefId?: string | undefined;
  refreshDelay: number | false;
  removeRowOnSuccess?: boolean | undefined;
  successMessage?: string | undefined;
  tableToUpdate?: string;
  updateField?: string;
  options?: ButtonProps[] | SelectableValue[];
  variables?: Record<string, string> | undefined;
}

export interface ButtonProps {
  value: string;
  icon: IconName;
  tooltip: string;
  tableToUpdate?: string | undefined;
  updateField?: string | undefined;
  topic?: string | undefined;
}

export interface Props {
  cell: Cell;
  tableStyles: TableStyles;
  onCellFilterAdded?: TableFilterActionCallback;
  columnIndex: number;
  columnCount: number;
  timeRange?: TimeRange;
  userProps?: object;
  frame: DataFrame;
  queries?: { [id: string]: SelectableValue[] };
  clickableCell?: ClickableCell;
  onCellChanged?: (
    clickableCell: ClickableCell,
    valueColumnName: string,
    value: string | number | boolean,
    topic: string | undefined,
    tableToUpdate: string | undefined,
    updateField: string | undefined
  ) => void;
}

export const TableCell = ({
  cell,
  tableStyles,
  onCellFilterAdded,
  timeRange,
  userProps,
  frame,
  clickableCell,
  onCellChanged,
  queries,
}: Props) => {
  const cellProps = cell.getCellProps();
  const field = (cell.column as unknown as GrafanaTableColumn).field;

  const [isInputVisible, setIsInputVisible] = useState(['button', 'switch'].includes(clickableCell?.type ?? ''));
  const [inputValue, setInputValue] = useState(cell.value);

  const options = useMemo(() => {
    if (!clickableCell) {
      return [];
    }

    const queryRefId = clickableCell.queryRefId;

    if (!queries || !queryRefId) {
      return clickableCell.options;
    }

    return queries[queryRefId];
  }, [queries, clickableCell]);

  if (!field?.display) {
    return null;
  }

  function getCellColorByThresholds(value: number, thresholds: ThresholdsConfig): Property.Color | undefined {
    // Ensure thresholds are sorted by value, although this should already be the case
    const steps = thresholds.steps;

    for (let i = steps.length - 1; i >= 0; i--) {
      const step = steps[i];

      // If the step value is null, treat it as -Infinity, which matches all values
      if (step.value === null || value >= step.value) {
        return step.color;
      }
    }

    return undefined; // Default color if no threshold is met
  }

  function evaluateCondition(value: any, matcher: { operator: string; value: string }): boolean {
    const { operator, value: matchValue } = matcher;

    switch (operator) {
      case 'true':
        return value === true;

      case 'false':
        return value === false;

      case 'null':
        return value === null;

      case '!null':
        return value !== null;

      case '=':
        // eslint-disable-next-line eqeqeq
        return value == matchValue;

      case '<':
        return typeof value === 'number' && value < parseFloat(matchValue);

      case '>':
        return typeof value === 'number' && value > parseFloat(matchValue);

      default:
        console.warn(`Unknown operator: ${operator}`);
        return false;
    }
  }

  // Get style by row highlight
  function getCellHighlightStyle(
    cell: any,
    highlight: HighlightOption
  ):
    | {
        color: Property.Color;
        background: Property.Background;
        icon?: IconName | undefined;
        iconPosition?: 'start' | 'end' | undefined;
        iconColor: string | undefined;
        iconSize: 'small' | 'medium' | 'large';
      }
    | undefined {
    const cellValueToMatch = cell.row.values[highlight.basedOn.column];

    if (evaluateCondition(cellValueToMatch, highlight.matcher)) {
      return {
        color: highlight.color,
        background: highlight.backgroundColor,
        icon: highlight.icon ?? undefined,
        iconPosition: highlight.iconPosition ?? 'start',
        iconColor: highlight.iconColor ?? undefined,
        iconSize: highlight.iconSize ?? 'small',
      };
    }

    return undefined;
  }

  // Kindof hacky, but we need to make sure the icon is centered in the cell. Cell already has the correct position.
  const iconStyle = { ...cellProps.style };
  let icon: IconName | null = null;

  let tooltip = '';

  if (cellProps.style) {
    if (field.config.custom.rowHighlight && field.config.custom.rowHighlight.isActive) {
      const highlightStyles = getCellHighlightStyle(cell, field.config.custom.rowHighlight);
      if (highlightStyles) {

        const tooltipMatch = field.config.custom.rowHighlight.tooltip.match(/\["(.*?)"\]/);
        const tooltipFieldMatch = tooltipMatch ? tooltipMatch[1] : null;

        if (tooltipFieldMatch) {
          const lowerCaseIfString = (value: any) => typeof value === 'string' ? value.toLowerCase() : value;
          const tooltipMatchValue = cell.row.cells.find(f => lowerCaseIfString(f.column.Header) === lowerCaseIfString(tooltipFieldMatch));
          tooltip = tooltipMatchValue ? tooltipMatchValue.value + '' : '';
        } else {
          tooltip = field.config.custom.rowHighlight.tooltip;
        }

        cellProps.style.color = highlightStyles.color;
        cellProps.style.background = highlightStyles.background;
        cellProps.style.borderRight = '1px solid #D1D1D1';

        if (iconStyle && highlightStyles.icon) {
          icon = highlightStyles.icon;
          iconStyle.display = 'flex';
          iconStyle.alignItems = 'center';
          iconStyle.height = '100%';
          iconStyle.zIndex = 99999; // Ensure it's on top
          iconStyle.padding = '0 10px';

          iconStyle.justifyContent = highlightStyles.iconPosition === 'end' ? 'flex-end' : 'flex-start';
          iconStyle.color = highlightStyles.iconColor ?? highlightStyles.color;
          iconStyle.fontSize =
            highlightStyles.iconSize === 'small' ? '1em' : highlightStyles.iconSize === 'medium' ? '1.5em' : '2em';
        }
      }
    } else if (field.config.thresholds) {
      cellProps.style.color = getCellColorByThresholds(parseFloat(cell.value as string), field.config.thresholds);
    }
    cellProps.style.minWidth = cellProps.style.width;
    cellProps.style.justifyContent = (cell.column as any).justifyContent;
  }

  let innerWidth = ((cell.column.width as number) ?? 24) - tableStyles.cellPadding * 2;

  const handleCellClick = (event: React.MouseEvent<HTMLElement>) => {
    if (clickableCell && clickableCell.isClickable && !isInputVisible) {
      setIsInputVisible(true);
    }
  };

  const handleInputChange = (event: any) => {
    if (clickableCell?.type === 'button') {
      setInputValue(event.value);
      setIsInputVisible(false);
      valueChanged(event.value);
    } else {
      setInputValue(event.target.value);
    }
  };

  const handleOnSelect = (selection: SelectableValue | null) => {
    if (clickableCell?.type === 'select' && selection) {
      setInputValue(selection.value);
      setIsInputVisible(false);
      valueChanged(selection.value, selection.label);
    }
  };

  const handleInputKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setIsInputVisible(false);
      const value = clickableCell?.type === 'number' ? parseFloat(inputValue) : inputValue;
      valueChanged(value);
    }
  };

  const handleOnSwitch = () => {
    setInputValue(!inputValue);
    valueChanged(inputValue);
  };

  const handleOnBlur = () => {
    setIsInputVisible(false);
  };

  const valueChanged = (
    value: string | number | boolean,
    displayValue?: string | null,
    topic?: string | undefined,
    tableToUpdate?: string | undefined,
    updateField?: string | undefined
  ) => {
    if (onCellChanged && clickableCell) {
      onCellChanged(clickableCell, field.name, value, topic, tableToUpdate, updateField);
      cell.value = displayValue ?? value;
    }
  };

  const cellRender = (
    <div>
      {icon && (
        <div style={iconStyle}>
            {tooltip ? (
            <Tooltip content={tooltip} placement="bottom">
              <div className={field.config.custom.rowHighlight?.iconAnimation}>
              <Icon name={icon} size="sm" />
              </div>
            </Tooltip>
            ) : (
            <div className={field.config.custom.rowHighlight?.iconAnimation}>
              <Icon name={icon} size="sm" />
            </div>
            )}
        </div>
      )}
      {cell.render('Cell', {
        field,
        tableStyles,
        onCellFilterAdded,
        cellProps,
        innerWidth,
        timeRange,
        userProps,
        frame,
      })}
    </div>
  );

  if (cellProps.style) {
    cellProps.style.height = '100%';
  }

  const buttonRowStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: 2,
    marginLeft: 2,
  };

  const inputField = (
    <div style={cellProps.style}>
      {clickableCell?.type === 'button' ? (
        <div style={buttonRowStyle}>
          {clickableCell.options?.map((element, index) => {
            const e = element as ButtonProps;
            return (
              <Button
                key={index}
                size="md"
                icon={e.icon}
                tooltip={e.tooltip}
                onClick={() => {
                  valueChanged(e.value, undefined, e.topic, e.tableToUpdate, e.updateField);
                }}
                variant="secondary"
              ></Button>
            );
          })}
        </div>
      ) : clickableCell?.type === 'select' ? (
        <Select
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }), // ensure it's on top
          }}
          options={options}
          onChange={handleOnSelect}
          onBlur={handleOnBlur}
          value={inputValue}
          autoFocus={true}
          openMenuOnFocus={true}
          menuPortalTarget={document.body}
          menuPosition="fixed"
        />
      ) : clickableCell?.type === 'switch' ? (
        <div style={{ display: 'flex', height: '100%', alignItems: 'center', marginLeft: 2 }}>
          <Switch value={inputValue} onChange={handleOnSwitch} />
        </div>
      ) : (
        <input
          type={clickableCell?.type}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          onBlur={handleOnBlur}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          style={{ textAlign: 'right', width: '100%', height: '100%' }}
        />
      )}
    </div>
  );

  return (
    <>
      <div role="presentation" onClick={handleCellClick}>
        {isInputVisible ? inputField : cellRender}
      </div>
    </>
  );
};
