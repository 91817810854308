import { css } from '@emotion/css';
import { merge } from 'lodash';
import React, { useEffect, useState } from 'react';

import { FieldConfigEditorProps, GrafanaTheme2, SelectableValue } from '@grafana/data';
import { config } from '@grafana/runtime';
import { TableCellOptions } from '@grafana/schema';
import {
  ColorPicker,
  Field,
  Input,
  RadioButtonGroup,
  Select,
  Switch,
  TableCellDisplayMode,
  useStyles2,
} from '@grafana/ui';

import { BarGaugeCellOptionsEditor } from './cells/BarGaugeCellOptionsEditor';
import { ColorBackgroundCellOptionsEditor } from './cells/ColorBackgroundCellOptionsEditor';
import { SparklineCellOptionsEditor } from './cells/SparklineCellOptionsEditor';

interface HighlightOption {
  basedOn: { name: string; column: number };
  matcher: {
    operator: string;
    value: string;
  };
  color: string;
  backgroundColor: string;
  icon?: string;
  iconPosition?: 'start' | 'end';
  iconColor?: string;
  iconSize?: 'small' | 'medium' | 'large';
  isActive: boolean;
  tooltip?: string;
  iconAnimation?: string;
}

const operatorOptions = [
  { label: '=', value: '=' },
  { label: '<', value: '<' },
  { label: '>', value: '>' },
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
  { label: 'null', value: 'null' },
  { label: '!null', value: '!null' },
];

// The props that any cell type editor are expected
// to handle. In this case the generic type should
// be a discriminated interface of TableCellOptions
export interface TableCellEditorProps<T> {
  cellOptions: T;
  onChange: (value: T) => void;
}

interface Props {
  value: TableCellOptions;
  onChange: (v: TableCellOptions) => void;
}

export const UpdateBasedOnOptionEditor: React.FC<FieldConfigEditorProps<HighlightOption, any>> = ({
  value = {
    basedOn: { name: '', column: -1 },
    matcher: { operator: '=', value: '' },
    color: '#E02F44',
    backgroundColor: '#FFFFFF',
    icon: '',
    iconPosition: 'start',
    iconSize: 'small',
    iconColor: '#000000',
    isActive: true,
    tooltip: '',
    iconAnimation: '',
  },
  onChange,
  context,
}) => {
  const [basedOn, setBasedOn] = useState<SelectableValue<{ name: string; column: number }>>({
    label: value.basedOn.name || '',
    value: value.basedOn || { name: '', column: -1 },
  });
  const [operator, setOperator] = useState(value.matcher.operator || '=');
  const [matchValue, setMatchValue] = useState(value.matcher.value || '');
  const [color, setColor] = useState(value.color || '#E02F44');
  const [backgroundColor, setBackgroundColor] = useState(value.backgroundColor || '#FFFFFF');
  const [icon, setIcon] = useState(value.icon || '');
  const [isActive, setIsActive] = useState(value.isActive);
  const [iconPosition, setIconPosition] = useState(value.iconPosition || 'start');
  const [iconSize, setIconSize] = useState(value.iconSize || 'small');
  const [iconColor, setIconColor] = useState(value.iconColor || '#000000');
  const [columnOptions, setColumnOptions] = useState<Array<SelectableValue<{ name: string; column: number }>>>([]);
  const [tooltip, setTooltip] = useState(value.tooltip || '');
  const [iconAnimation, setIconAnimation] = useState(value.iconAnimation || '');
  const [iconAnimationOptions] = useState<Array<SelectableValue<string>>>([
    {
      label: 'None',
      value: '',
    },
    {
      label: 'Pulse',
      value: 'pulse',
    },
    {
      label: 'Bounce',
      value: 'bounce',
    },
    {
      label: 'Rotate',
      value: 'rotate',
    },
    {
      label: 'Fade',
      value: 'fade',
    },
  ]);

  // Effect to dynamically retrieve column names and indexes from the table
  useEffect(() => {
    if (context.data) {
      const columns = context.data.flatMap((frame) =>
        frame.fields.map((field, colIdx) => ({
          label: `${field.name} (Column: ${colIdx})`,
          value: { name: field.name, column: colIdx },
        }))
      );
      setColumnOptions(columns);
    }
  }, [context.data]);

  const onBasedOnChange = (option: SelectableValue<{ name: string; column: number }>) => {
    setBasedOn(option);
    onChange({
      ...value,
      basedOn: option.value || { name: '', column: -1 },
    });
  };

  const onOperatorChange = (option: SelectableValue<string>) => {
    setOperator(option.value || '=');
    onChange({
      ...value,
      matcher: { ...value.matcher, operator: option.value || '=' },
    });
  };

  const onMatchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setMatchValue(newValue);
    onChange({
      ...value,
      matcher: { ...value.matcher, value: newValue },
    });
  };

  const onColorChange = (color: string) => {
    setColor(color);
    onChange({
      ...value,
      color,
    });
  };

  const onBackgroundColorChange = (color: string) => {
    setBackgroundColor(color);
    onChange({
      ...value,
      backgroundColor: color,
    });
  };

  const onIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const iconValue = e.target.value;
    setIcon(iconValue);
    onChange({
      ...value,
      icon: iconValue,
    });
  };

  const onIconPositionChange = (position: 'start' | 'end') => {
    setIconPosition(position);
    onChange({
      ...value,
      iconPosition: position,
    });
  };

  const onIconSizeChange = (size: 'small' | 'medium' | 'large') => {
    setIconSize(size);
    onChange({
      ...value,
      iconSize: size,
    });
  };

  const onIconColorChange = (color: string) => {
    setIconColor(color);
    onChange({
      ...value,
      iconColor: color,
    });
  };

  const onActiveChange = (checked: boolean) => {
    setIsActive(checked);
    onChange({
      ...value,
      isActive: checked,
    });
  };

  const onTooltipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setTooltip(newValue);
    onChange({
      ...value,
      tooltip: newValue,
    });
  };

  const onIconAnimationChange = (option: SelectableValue<string>) => {
    setIconAnimation(option.value || '');
    onChange({
      ...value,
      iconAnimation: option.value || '',
    });
  };

  const styles = {
    wrapper: css`
      background: #eeeeef;
      padding: 0.5em;
    `,
  };

  return (
    <div className={styles.wrapper}>
      <Field label="Activate Highlighting">
        <Switch value={isActive} onChange={(e) => onActiveChange(e.currentTarget.checked)} />
      </Field>
      <Field label="Based on Column">
        <Select options={columnOptions} value={basedOn} onChange={onBasedOnChange} />
      </Field>
      <Field label="Operator">
        <Select options={operatorOptions} value={{ label: operator, value: operator }} onChange={onOperatorChange} />
      </Field>
      <Field label="Match Condition Value">
        <Input value={matchValue} placeholder="Enter match condition, e.g., true" onChange={onMatchValueChange} />
      </Field>
      <Field label="Text Color">
        <ColorPicker color={color} onChange={onColorChange} />
      </Field>
      <Field label="Background Color">
        <ColorPicker color={backgroundColor} onChange={onBackgroundColorChange} />
      </Field>
      <Field label="Icon">
        <Input value={icon} placeholder="Enter icon name, e.g., 'star'" onChange={onIconChange} />
      </Field>
      <Field label="Icon position">
        <RadioButtonGroup
          options={[
            { label: 'Left', value: 'start' },
            { label: 'Right', value: 'end' },
          ]}
          value={iconPosition}
          onChange={onIconPositionChange}
        />
      </Field>
      <Field label="Icon size">
        <RadioButtonGroup
          options={[
            { label: 'Small', value: 'small' },
            { label: 'Medium', value: 'medium' },
            { label: 'Large', value: 'large' },
          ]}
          value={iconSize}
          onChange={onIconSizeChange}
        />
      </Field>
      <Field label="Icon color">
        <ColorPicker color={iconColor} onChange={onIconColorChange} />
      </Field>
      <Field label="Icon Animation">
        <Select options={iconAnimationOptions} value={iconAnimation} onChange={onIconAnimationChange} />
      </Field>
      <Field label="Tooltip">
        <Input value={tooltip} placeholder="Enter tooltip text" onChange={onTooltipChange} />
      </Field>
    </div>
  );
};

export const TableCellOptionEditor = ({ value, onChange }: Props) => {
  const cellType = value.type;
  const styles = useStyles2(getStyles);
  const currentMode = cellDisplayModeOptions.find((o) => o.value!.type === cellType)!;
  let [settingCache, setSettingCache] = useState<Record<string, TableCellOptions>>({});

  // Update display mode on change
  const onCellTypeChange = (v: SelectableValue<TableCellOptions>) => {
    if (v.value !== undefined) {
      // Set the new type of cell starting
      // with default settings
      value = v.value;

      // When changing cell type see if there were previously stored
      // settings and merge those with the changed value
      if (settingCache[value.type] !== undefined && Object.keys(settingCache[value.type]).length > 1) {
        value = merge(value, settingCache[value.type]);
      }

      onChange(value);
    }
  };

  // When options for a cell change we merge
  // any option changes with our options object
  const onCellOptionsChange = (options: TableCellOptions) => {
    settingCache[value.type] = merge(value, options);
    setSettingCache(settingCache);
    onChange(settingCache[value.type]);
  };

  // Setup and inject editor
  return (
    <div className={styles.fixBottomMargin}>
      <Field>
        <Select options={cellDisplayModeOptions} value={currentMode} onChange={onCellTypeChange} />
      </Field>
      {cellType === TableCellDisplayMode.Gauge && (
        <BarGaugeCellOptionsEditor cellOptions={value} onChange={onCellOptionsChange} />
      )}
      {cellType === TableCellDisplayMode.ColorBackground && (
        <ColorBackgroundCellOptionsEditor cellOptions={value} onChange={onCellOptionsChange} />
      )}
      {cellType === TableCellDisplayMode.Sparkline && (
        <SparklineCellOptionsEditor cellOptions={value} onChange={onCellOptionsChange} />
      )}
    </div>
  );
};

const SparklineDisplayModeOption: SelectableValue<TableCellOptions> = {
  value: { type: TableCellDisplayMode.Sparkline },
  label: 'Sparkline',
};

const cellDisplayModeOptions: Array<SelectableValue<TableCellOptions>> = [
  { value: { type: TableCellDisplayMode.Auto }, label: 'Auto' },
  ...(config.featureToggles.timeSeriesTable ? [SparklineDisplayModeOption] : []),
  { value: { type: TableCellDisplayMode.ColorText }, label: 'Colored text' },
  { value: { type: TableCellDisplayMode.ColorBackground }, label: 'Colored background' },
  { value: { type: TableCellDisplayMode.Gauge }, label: 'Gauge' },
  { value: { type: TableCellDisplayMode.JSONView }, label: 'JSON View' },
  { value: { type: TableCellDisplayMode.Image }, label: 'Image' },
];

const getStyles = (theme: GrafanaTheme2) => ({
  fixBottomMargin: css({
    marginBottom: theme.spacing(-2),
  }),
});
